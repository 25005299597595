import React from 'react';
import PropTypes from 'prop-types';

const Block = ({ emoji, background, html }) => (
  <div
    className="flex p-4 mb-4 lg:w-3/4"
    style={{ backgroundColor: background }}
  >
    <span>{emoji}</span>
    {/*  eslint-disable-next-line react/no-danger */}
    <div dangerouslySetInnerHTML={{ __html: html }} />
  </div>
);

Block.propTypes = {
  emoji: PropTypes.string,
  background: PropTypes.string,
  html: PropTypes.string,
};

export default Block;
