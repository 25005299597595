import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import List from 'list.js';
import './App.css';
import Block from './Block';
import { db } from './Firebase';
import UserOverview from './UserOverview';

// eslint-disable-next-line react/prop-types
const Editor = ({ loading, setLoading, users }) => {
  // eslint-disable-next-line no-unused-vars
  const [template, setTemplate] = useState('');
  const [values, setValues] = useState({
    editorNote: {
      html: '',
      backgroundColor: '#f0f4fe',
      emoji: '📝',
    },
    unsubscribeNote: {
      html: '',
      backgroundColor: '#fef0f0',
      emoji: '⛔️',
    },
    featuredCompanyNote: {
      html: '',
      backgroundColor: '#fff3fc',
      emoji: '✨',
    },
    feedbackNote: {
      html: '',
      backgroundColor: '#fbf0fe',
      emoji: '👎',
    },
    forwardNote: {
      html: '',
      backgroundColor: '#fef0f0',
      emoji: '👀',
    },
    pluginNote: {
      html: '',
      backgroundColor: '#f1f0fe',
      emoji: '📮',
    },
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await db.collection('variables').doc('blocks').update(values);
    setLoading(false);
  };

  useEffect(() => {
    async function fetchValues() {
      const snap = await db.doc('variables/blocks').get();
      const templateSnap = await db.doc('templates/main').get();

      setValues((prevValues) => ({ ...prevValues, ...snap.data() }));
      List('users', {
        valueNames: [
          'id',
          'email',
          'createdAt',
          'confirmed',
          'companies',
          'totalCompanyCount',
          'reminder',
        ],
      });
      setTemplate(templateSnap.get('html'));
    }
    return fetchValues();
  }, []);

  const handleInputChange = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: {
        ...values[e.target.name],
        html: e.target.value,
      },
    }));
  };

  const sendReminder = async (e, emailAddress) => {
    e.preventDefault();
    setLoading(true);
    await Axios.post(
      `https://us-central1-greatwork-jobs.cloudfunctions.net/app/reminders/${emailAddress}`
    );
    setLoading(false);
  };

  const getUserEmailsAsCSV = () => {
    const emails = users.allUsers.filter((user) => user.confirmed).map((user) => user.emailAddress);
    console.table(emails);
    const csv = emails.join(',');
    console.log(csv);
  };

  return (
    <div>
      <div className="grid-cols-2 lg:grid">
        <div className="editor">
          <form className="flex flex-col w-4/5 m-auto" onSubmit={onSubmit}>
            {Object.keys(values).map((key) => (
              <label className="mb-4 font-bold" htmlFor={key} key={key}>
                {key}
                <textarea
                  className="w-full"
                  type="text"
                  onChange={handleInputChange}
                  value={values[key].html}
                  id={key}
                  name={key}
                  placeholder={key}
                />
              </label>
            ))}

            <button
              disabled={loading}
              className={`w-32 p-4 text-white rounded ${
                loading
                  ? 'bg-gray-500 cursor-default'
                  : 'bg-green-700 hover:bg-green-600'
              }
          } w-32 p-4 m-auto my-4 text-white bg-black rounded`}
              type="submit"
            >
              {loading ? 'Loading...' : 'Update'}
            </button>
          </form>
        </div>
        <div className="p-2 preview">
          <h2 className="mb-2 text-3xl font-bold">Preview</h2>
          {Object.entries(values).map(([key, value]) => (
            <Block
              key={key}
              emoji={value.emoji}
              html={value.html}
              background={value.backgroundColor}
            />
          ))}
        </div>
      </div>

      <div className="p-2 text-center lg:p-12 user-overview-container users bg-indigo-50">
        <button
          className="p-4 m-auto my-4 text-white bg-black rounded"
          type="button"
          onClick={getUserEmailsAsCSV}
        >
          Get Active User Emails
        </button>

        <UserOverview
          sendReminder={sendReminder}
          loading={loading}
          users={users}
        />
      </div>
    </div>
  );
};

export default Editor;
