import React from 'react';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { auth, uiConfig } from './Firebase';

const Signin = () => (
  <div>
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth()} />
  </div>
);

export default Signin;
