import React, { useState } from 'react';
import { db, functions } from './Firebase';

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
const Admin = () => {
  const [companyInput, setCompanyInput] = useState('');
  const [targetEmailCollectionInput, setTargetEmailCollectionInput] =
    useState('mail');
  const [filterInput, setFilterInput] = useState('');
  const [userInput, setUserInput] = useState('');
  const [jobs, setJobs] = useState([]);
  const fetchWeeklyJobs = functions.httpsCallable('fetchWeeklyJobs');
  const storeJobs = functions.httpsCallable('createStoreCompanyJobsRequests');
  const regenerateCompanyBlurbs = functions.httpsCallable(
    'regenerateCompanyBlurbs'
  );
  const createWeeklyDispatch = functions.httpsCallable('createWeeklyDispatch');
  const sendWeeklyDispatch = functions.httpsCallable('sendWeeklyDispatch');

  const handleFetchWeeklyJobs = () => {
    if (window.confirm('Are you sure you want to fetch weekly new jobs?')) {
      fetchWeeklyJobs({ eventId: Date.now() });
    }
  };

  const handleSendWeeklyDispatch = async () => {
    if (
      window.confirm(
        `Are you sure you want to send the weekly dispatch to ${targetEmailCollectionInput}?`
      )
    ) {
      await sendWeeklyDispatch({
        eventId: Date.now(),
        targetCollection: targetEmailCollectionInput,
      });
    }
  };

  const handleCreateWeeklyDispatch = () => {
    if (
      window.confirm('Are you sure you want to create the weekly dispatch?')
    ) {
      createWeeklyDispatch({ eventId: Date.now() });
    }
  };

  const handleRegenerateCompanyBlurbs = () => {
    if (
      window.confirm('Are you sure you want to regenerate all company blurbs?')
    ) {
      regenerateCompanyBlurbs({ eventId: Date.now() });
    }
  };

  const handleStoreJobs = () => {
    if (
      window.confirm(
        'Are you sure you want to fetch and store new jobs in the jobs collection?'
      )
    ) {
      storeJobs({ eventId: Date.now() });
    }
  };

  return (
    <div className="flex flex-col justify-center w-full max-w-screen-xl gap-8 p-4 m-auto my-12 rounded bg-gray-50 admin-actions">
      <h2 className="max-w-md p-4 m-auto text-3xl font-bold text-center">
        Admin panel
      </h2>

      <div className="p-4 bg-white rounded ">
        <h4 className="mb-2 text-lg font-medium rounded ">Create export</h4>
        <a
          href="https://console.cloud.google.com/firestore/import-export?project=greatwork-jobs"
          target="_blank"
          rel="noreferrer"
          className="p-2 font-bold text-white no-underline bg-red-500 rounded hover:bg-red-700"
          type="button"
        >
          Create export
        </a>
      </div>
      <div className="p-4 bg-white rounded ">
        <h4 className="mb-2 text-lg font-medium rounded ">
          Fetch all new jobs
        </h4>
        <button
          className="p-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
          type="button"
          onClick={handleFetchWeeklyJobs}
        >
          Fetch
        </button>
        <a
          href="https://console.cloud.google.com/logs/query;query=resource.type%20%3D%20%22cloud_function%22%0Aresource.labels.function_name%20%3D%20%22fetchJobsForCompany%22%0Aresource.labels.region%20%3D%20%22us-central1%22%0A%20severity%3E%3DDEFAULT?project=greatwork-jobs"
          target="_blank"
          rel="noreferrer"
          className="float-right"
        >
          View logs
        </a>
      </div>
      <div className="p-4 bg-white rounded ">
        <h4 className="mb-2 text-lg font-medium rounded ">
          Store new jobs in jobs collection
        </h4>
        <button
          className="p-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
          type="button"
          onClick={handleStoreJobs}
        >
          Fetch and store
        </button>
        <a
          href="https://console.cloud.google.com/logs/query;query=resource.type%3D%22cloud_function%22%0Aresource.labels.function_name%3D%22storeJobsForcompany%22%0Aresource.labels.region%3D%22us-central1%22?project=greatwork-jobs"
          target="_blank"
          rel="noreferrer"
          className="float-right"
        >
          View logs
        </a>
      </div>

      <div className="p-4 bg-white rounded ">
        <h4 className="mb-2 text-lg font-medium rounded ">
          Generate user dispatches
        </h4>
        <button
          className="p-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
          type="button"
          onClick={handleCreateWeeklyDispatch}
        >
          Generate
        </button>
        <a
          href="https://console.cloud.google.com/logs/query;query=resource.type%20%3D%20%22cloud_function%22%0Aresource.labels.function_name%20%3D%20%22createDispatchForUser%22%0Aresource.labels.region%20%3D%20%22us-central1%22%0A%20severity%3E%3DDEFAULT?project=greatwork-jobs"
          target="_blank"
          rel="noreferrer"
          className="float-right"
        >
          View logs
        </a>
      </div>
      <div className="p-4 bg-white rounded ">
        <h4 className="mb-2 text-lg font-medium rounded ">
          Send weekly dispatch to all users
        </h4>
        <input
          type="text"
          defaultValue="mail"
          placeholder="Target email collection"
          name="targetEmailCollection"
          onChange={(e) => setTargetEmailCollectionInput(e.target.value)}
          value={targetEmailCollectionInput}
        />
        <button
          className="p-2 font-bold text-white bg-red-500 rounded md:ml-4 hover:bg-red-700"
          type="button"
          onClick={handleSendWeeklyDispatch}
        >
          Send
        </button>
        <a
          href="https://console.cloud.google.com/logs/query;query=resource.type%3D%22cloud_function%22%0Aresource.labels.function_name%3D%22sendUserDispatch%22%0Aresource.labels.region%3D%22us-central1%22?project=greatwork-jobs"
          target="_blank"
          rel="noreferrer"
          className="float-right"
        >
          View logs
        </a>
      </div>
      <div className="p-4 bg-white rounded ">
        <h4 className="mb-2 text-lg font-medium rounded ">
          Re-generate all company blurbs
        </h4>
        <button
          className="p-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
          type="button"
          onClick={handleRegenerateCompanyBlurbs}
        >
          Generate
        </button>
        <a
          href="https://console.cloud.google.com/logs/query?advancedFilter=resource.type%20%3D%20%22cloud_function%22%0Aresource.labels.function_name%20%3D%20%22handleUpdateCompany%22%0Aresource.labels.region%20%3D%20%22us-central1%22%0A%20severity%3E%3DDEFAULT&project=greatwork-jobs"
          target="_blank"
          rel="noreferrer"
          className="float-right"
        >
          View logs
        </a>
      </div>

      <div className="items-center p-4 bg-white rounded">
        <h4 className="mb-2 text-lg font-medium rounded font">
          Generate dispatch for user
        </h4>
        <input
          type="text"
          name="company"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
        />
        <button
          type="button"
          className="p-2 font-bold text-white bg-red-500 rounded md:ml-4 hover:bg-red-700"
          onClick={async () => {
            const userUpdateObject = {
              timeStamp: new Date(),
              userId: userInput,
              departmentsFlag: true,
            };
            if (
              window.confirm(
                `Warning! You're about to generate a dispatch for ${userInput}. This can not be undone.`
              )
            ) {
              db.collection('userCreateDispatchRequests').add(userUpdateObject);
              alert('Done!');
            }
          }}
        >
          Generate dispatch
        </button>
      </div>
      <div className="items-center p-4 bg-white rounded">
        <h4 className="mb-2 text-lg font-medium rounded font">
          Force fetch jobs for a company
        </h4>
        <input
          type="text"
          name="company"
          value={companyInput}
          onChange={(e) => setCompanyInput(e.target.value)}
        />
        <button
          type="button"
          className="p-2 font-bold text-white bg-red-500 rounded md:ml-4 hover:bg-red-700"
          onClick={async () => {
            const companyUpdateObject = {
              timeStamp: new Date(),
              idempotencyKey: `EDITOR-FETCH-REQUEST-${new Date().toISOString()}`,
              processed: false,
              companyId: capitalize(companyInput),
              createUpdateBlurbRequest: true,
            };
            if (
              window.confirm(
                `Warning! You're about to fetch new jobs for ${companyUpdateObject.companyId}. This can not be undone.`
              )
            ) {
              db.collection('companyFetchJobsRequests').add(
                companyUpdateObject
              );
              alert('Done!');
            }
          }}
        >
          Fetch jobs for company
        </button>
      </div>
      <h2 className="text-2xl font-semibold text-gray-900">Utilities</h2>

      <div className="items-center p-4 bg-white rounded">
        <h4 className="mb-2 text-lg font-medium">
          Search in job title and department
        </h4>
        <input
          type="text"
          name="company"
          value={filterInput}
          onChange={(e) => setFilterInput(e.target.value)}
        />
        <button
          type="button"
          className="p-2 mb-8 font-bold text-white bg-gray-700 rounded md:ml-4 hover:bg-gray-900"
          onClick={async () => {
            const companies = await db.collection('companies').get();

            const _jobs = [];

            companies.forEach((doc) => {
              const companyJobs = doc.data().jobs;
              Object.values(companyJobs).forEach((j) => {
                if (
                  j.title.toLowerCase().includes(filterInput) ||
                  j.department.toLowerCase().includes(filterInput)
                ) {
                  _jobs.push({ companyName: doc.id, ...j });
                }
              });
            });

            setJobs(_jobs);
          }}
        >
          Find jobs
        </button>
        <br />
        {jobs && <span className="font-semibold">{jobs.length} Results</span>}
        <textarea
          style={{ width: '100%', minHeight: '200px', marginTop: '10px' }}
          value={JSON.stringify(jobs)}
        />
        <a
          href="https://www.convertcsv.com/json-to-csv.htm"
          target="_blank"
          rel="noreferrer"
          className="float-right ml-8"
        >
          Go to jsontocsv
        </a>
        <button
          className="float-right"
          type="button"
          onClick={() => {
            navigator.clipboard.writeText(JSON.stringify(jobs));
          }}
        >
          Copy
        </button>
      </div>
    </div>
  );
};

export default Admin;
