import { isWithinInterval, subDays } from 'date-fns';

// eslint-disable-next-line no-unused-vars
const format = (html) => {
  const tab = '\t';
  let result = '';
  let indent = '';

  html.split(/>\s*</).forEach(function (element) {
    if (element.match(/^\/\w/)) {
      indent = indent.substring(tab.length);
    }

    result += `${indent}<${element}>\r\n`;

    if (element.match(/^<?\w[^>]*[^/]$/) && !element.startsWith('input')) {
      indent += tab;
    }
  });

  return result.substring(1, result.length - 3);
};
const median = (arr) => {
  const mid = Math.floor(arr.length / 2);
  const nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export const newUsersSince = (users, { start, end }) =>
  users.reduce((acc, user) => {
    if (
      isWithinInterval(user.createdAt.toDate(), {
        start,
        end,
      })
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);

export const newUserWeeklyGrowth = (users) => {
  const usersThisWeek = newUsersSince(users, {
    start: subDays(new Date(), 7),
    end: new Date(),
  });

  const usersPreviousWeek = newUsersSince(users, {
    start: subDays(new Date(), 14),
    end: subDays(new Date(), 7),
  });

  return ((usersThisWeek - usersPreviousWeek) / usersPreviousWeek) * 100;
};

export const percentageConfirmedEmail = (users) => {
  const totalConfirmedEmails = users.reduce((acc, user) => {
    if (user.confirmed && !user.unsubscribeDate) return acc + 1;
    return acc;
  }, 0);
  return (totalConfirmedEmails / users.length) * 100;
};

export const percentageConfirmedEmailLastWeek = (users) => {
  const weeklyConfirmedEmails = users.reduce((acc, user) => {
    if (
      isWithinInterval(user.createdAt.toDate(), {
        start: subDays(new Date(), 7),
        end: new Date(),
      }) &&
      user.confirmed &&
      !user.unsubscribeDate
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);

  return (
    (weeklyConfirmedEmails /
      newUsersSince(users, {
        start: subDays(new Date(), 7),
        end: new Date(),
      })) *
    100
  );
};

export const totalConfirmedUsers = (users) =>
  users.filter((user) => user.confirmed);

export const medianSubscribers = (users) =>
  median(users.map((user) => Object.keys(user.companies).length));
