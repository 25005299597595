import firebase from 'firebase';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDx0Qe5d9yxNJD-GxCC6hlV4tHS46cR6Qc',
  authDomain: 'greatwork-jobs.firebaseapp.com',
  databaseURL: 'https://greatwork-jobs.firebaseio.com',
  projectId: 'greatwork-jobs',
  storageBucket: 'greatwork-jobs.appspot.com',
  messagingSenderId: '555051327584',
  appId: '1:555051327584:web:58cc742d37435f48f66305',
  measurementId: 'G-2JYWB3GH7G',
};

firebase.initializeApp(firebaseConfig);

export const functions = firebase.functions();
export const db = firebase.firestore();
export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

export const { auth } = firebase;
