import Axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
function Header({ loading, setLoading, email }) {
  const [userEmail, setUserEmail] = useState(email);
  return (
    <nav className="sticky top-0 items-center p-4 mb-4 bg-gray-900 md:flex ">
      <div className="flex items-center flex-grow mb-4 md:mb-0">
        <Link className="mr-4 font-semibold text-white" to="/">
          Editor
        </Link>
        <Link
          className="px-4 py-2 font-semibold no-underline rounded hover:bg-yellow-100 bg-yellow-50 "
          to="/admin"
        >
          Admin
        </Link>
      </div>
      <div className="flex">
        <input
          className="mr-4"
          type="email"
          id="userEmail"
          name="userEmail"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
        />

        <button
          type="button"
          disabled={loading}
          className={`px-4 text-white rounded ${
            loading
              ? 'bg-gray-500 cursor-default'
              : 'bg-green-700 hover:bg-green-600'
          }
          }`}
          onClick={async () => {
            setLoading(true);
            try {
              await Axios.post(
                `https://us-central1-greatwork-jobs.cloudfunctions.net/app/dispatch/${userEmail}`
              );
            } catch (error) {
              alert(error);
            }
            setLoading(false);
          }}
        >
          {loading ? 'Loading...' : 'Send preview'}
        </button>
      </div>
    </nav>
  );
}

export default Header;
