/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import subDays from 'date-fns/subDays';
import Admin from './Admin';
import './App.css';
import { auth, db } from './Firebase';
import Header from './Header';
import Signin from './Signin';
import Editor from './Editor';
import {
  medianSubscribers,
  newUsersSince,
  percentageConfirmedEmailLastWeek,
  totalConfirmedUsers,
  newUserWeeklyGrowth,
} from './utils';

function App() {
  const [loading, setLoading] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [users, setUsers] = useState();
  const [loggedInUser, setLoggedInUser] = useState();
  useEffect(() => {
    async function fetchValues() {
      const userSnap = await db
        .collection('users')
        .orderBy('createdAt', 'desc')
        .get();
      const _users = userSnap.docs.map((userDoc) => ({
        id: userDoc.id,
        ...userDoc.data(),
      }));

      setUsers({
        allUsers: _users,
        newUsersLastWeek: newUsersSince(_users, {
          start: subDays(new Date(), 7),
          end: new Date(),
        }),
        newUsersWeeklyGrowth: newUserWeeklyGrowth(_users),
        percentageConfirmedEmail: percentageConfirmedEmailLastWeek(_users),
        totalConfirmedUsers: totalConfirmedUsers(_users),
        medianSubscribers: medianSubscribers(_users),
        percentageConfirmedEmailLastWeek:
          percentageConfirmedEmailLastWeek(_users),
      });
    }
    fetchValues();
  }, []);

  useEffect(() => {
    const unregisterAuthObserver = auth().onAuthStateChanged((user) => {
      setLoggedInUser(user);
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  if (!isSignedIn) {
    return (
      <div>
        <Signin />
      </div>
    );
  }

  return (
    <div>
      <Header
        loading={loading}
        setLoading={setLoading}
        email={loggedInUser.email}
      />
      <button
        onClick={async () => {
          const comps = await db.collection('companies').get();
          console.log(
            JSON.stringify({ companies: comps.docs.map((comp) => comp.id) })
          );
          console.log(comps.size);
        }}
        type="button"
      >
        Get companies as JSON in console
      </button>

      <Switch>
        <Route path="/admin">
          <Admin loading={loading} setLoading={setLoading} />
        </Route>
        <Route path="/">
          <Editor users={users} loading={loading} setLoading={setLoading} />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
