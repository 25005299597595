import React from 'react';
import PropTypes from 'prop-types';

const CONSOLE_URL_USER =
  'https://console.firebase.google.com/u/0/project/greatwork-jobs/firestore/data~2Fusers~2F';

// eslint-disable-next-line no-unused-vars
const UserOverview = ({ users, sendReminder, loading }) => (
  <>
    <h1 className="mb-12 text-6xl font-bold">User Overview</h1>
    <div className="m-auto mb-16 overflow-auto text-gray-900 border bg-gray-50">
      <table className="w-full text-center">
        <thead>
          <tr>
            <th className="p-4">New Users last 7 days</th>
            <th className="p-4">New Users WoW growth</th>
            <th className="p-4">% Confirmed last 7 days</th>
            <th className="p-4">Active subscribers</th>
            <th className="p-4">Median subscribers per company</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{users?.newUsersLastWeek}</td>
            <td>{users?.newUsersWeeklyGrowth.toFixed(2)}%</td>
            <td>{users?.percentageConfirmedEmailLastWeek.toFixed(2)}%</td>
            <td>
              {users?.totalConfirmedUsers.length} (
              {users?.percentageConfirmedEmail.toFixed(2)})%
            </td>
            <td>{users?.medianSubscribers}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="users" className="overflow-auto">
      <div className="my-4 text-left search-container">
        <input
          className="w-auto p-4 border-2 border-black rounded search"
          placeholder="Search users"
        />
      </div>
      <table className="p-4 border bg-gray-50">
        <thead className="text-xl">
          <tr>
            <th
              className="p-4 underline cursor-pointer sort hover:bg-gray-200"
              data-sort="id"
            >
              Id
            </th>
            <th
              className="p-4 underline cursor-pointer sort hover:bg-gray-200"
              data-sort="email"
            >
              Email
            </th>
            <th className="p-4">Created at</th>
            <th
              className="p-4 underline cursor-pointer sort hover:bg-gray-200"
              data-sort="confirmed"
            >
              Confirmed
            </th>
            <th className="p-4">Companies</th>
            <th
              className="p-4 underline cursor-pointer sort hover:bg-gray-200"
              data-sort="totalCompanyCount"
            >
              # Companies / # Jobs (last dispatch)
            </th>
            <th className="p-4">Actions</th>
          </tr>
        </thead>
        <tbody className="list">
          {users &&
            users.allUsers.map((user, index) => (
              <tr
                key={user.id}
                className={`${
                  index % 2 === 0 && 'bg-white'
                } hover:bg-blue-100 p-4`}
              >
                <td className="p-4 id">{users.allUsers.length - index}</td>
                <td className="p-4 email">{user.emailAddress}</td>
                <td className="p-4 createdAt">
                  {user.createdAt.toDate().toDateString()}
                </td>
                <td className="p-4 confirmed">
                  {user.unsubscribeDate && !user.confirmed ? (
                    <div className="px-2 bg-red-300 rounded color-white">
                      User unsubscribed
                    </div>
                  ) : (
                    <span
                      className={`${
                        user.confirmed ? 'bg-green-300' : 'bg-yellow-300'
                      } p-4 rounded font-medium`}
                    >
                      {user.confirmed.toString()}
                    </span>
                  )}
                </td>
                <td className="p-4 companies">
                  {Object.keys(user.companies).join(', ')}
                </td>
                <td className="w-64 p-4 totalCompanyCount">
                  {Object.entries(user.companies).length} /{' '}
                  {user.blurb.jobCount}
                  {/* {!user.confirmed &&
                    !user.unsubscribeDate &&
                    !user.reminderDate && (
                      // <button
                      //   type="button"
                      //   className="w-32 p-1 mb-1 text-white rounded"
                      //   onClick={async (e) => {
                      //     console.log('sending');
                      //     sendReminder(e, user.emailAddress);
                      //   }}
                      // >
                      //   {loading ? 'Loading...' : 'Send reminder'}
                      // </button>
                    )} */}
                </td>
                <td className="flex flex-col p-4 reminder">
                  <a
                    className="p-1 text-green-900 no-underline bg-gray-100 rounded hover:bg-green-300"
                    target="_blank"
                    rel="noreferrer"
                    href={user.blurb?.blurbUrl}
                  >
                    View dispatch
                  </a>
                  <a
                    className="p-1 text-green-900 no-underline bg-gray-100 rounded hover:bg-green-300"
                    target="_blank"
                    rel="noreferrer"
                    href={CONSOLE_URL_USER + user.id}
                  >
                    View in console
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  </>
);

UserOverview.propTypes = {
  users: PropTypes.object,
  sendReminder: PropTypes.func,
  loading: PropTypes.bool,
};

export default UserOverview;
